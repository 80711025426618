/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 aero_tower.gltf --transform --simplify
*/

import React, {useEffect, useMemo, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import useStatusStore, {Coloring} from "../store/useStatusStore";
import * as THREE from "three";
import {applyProps} from '@react-three/fiber'

export function Model(props) {
  const {status, setStatus, coloring, light} = useStatusStore()
  const {scene, nodes, materials } = useGLTF('/aerotower/aero_tower-transformed.glb')

  useMemo(()=>{

    console.log("%c coloring: " + coloring, "color: cyan")
    switch (coloring) {
      case Coloring.WHITE:
        applyProps(materials.Body, {color: "#E7E7E7", metalness: 0.05, roughness: 0.1})
        applyProps(materials.Frame_2, {color: "#E7E7E7", metalness: 0.05, roughness: 0.1})
        applyProps(materials.Handel, {color: "#E7E7E7", metalness: 0.05, roughness: 0.1})
        applyProps(materials.Bottom, {color: "#786c5f", metalness: 0.05, roughness: 0.1})
        break
      case Coloring.YELLOW:
        applyProps(materials.Body, {color: "#36453f", metalness: 0.05, roughness: 0.1})
        applyProps(materials.Frame_2, {color: "#36453f", metalness: 0.05, roughness: 0.1})
        applyProps(materials.Handel, {color: "#36453f", metalness: 0.05, roughness: 0.1})
        applyProps(materials.Bottom, {color: "#43433e", metalness: 0.05, roughness: 0.1})
        break
      case Coloring.RED:
        applyProps(materials.Body, {color: "#bb756e", metalness: 0.05, roughness: 0.1})
        applyProps(materials.Frame_2, {color: "#bb756e", metalness: 0.05, roughness: 0.1})
        applyProps(materials.Handel, {color: "#bb756e", metalness: 0.05, roughness: 0.1})
        applyProps(materials.Bottom, {color: "#786c5f", metalness: 0.05, roughness: 0.1})
        break
    }
  }, [coloring])

  return (
    <group {...props} dispose={null}>
      <mesh castShadow geometry={nodes.Bottom.geometry} material={materials.Bottom} />
      <mesh castShadow geometry={nodes.Handle.geometry} material={materials.Handel} />
      <mesh castShadow geometry={nodes.Body.geometry} material={materials.Body} />
      <mesh castShadow geometry={nodes.Filter.geometry} material={materials.Filter} />
      <mesh castShadow geometry={nodes.Frame.geometry} material={materials.Frame} />
      <mesh castShadow geometry={nodes.Frame_2.geometry} material={materials.Frame_2} />
      <mesh castShadow geometry={nodes.Bodydecal.geometry} material={materials.UI} />
      <mesh castShadow geometry={nodes.Framedecal.geometry} material={materials['filter.decal']} />
    </group>
  )
}

useGLTF.preload('/aerotower/aero_tower-transformed.glb')
