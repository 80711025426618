import React from 'react';
import {Environment, Lightformer} from "@react-three/drei";
import useStatusStore, {Status} from "../store/useStatusStore";
import {Effects} from "./Effects";

const EnvironmentController = () => {
  const {status, setStatus} = useStatusStore()

  return (
    <group>
      <hemisphereLight intensity={0.5}/>
      <Environment resolution={512}  frames={Infinity}  >
        {/* Ceiling */}
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -9]} scale={[10, 1, 1]}/>
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -6]} scale={[10, 1, 1]}/>
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]}/>
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]}/>
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]}/>
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]}/>
        <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]}/>
        {/* Sides */}
        <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-50, 2, 0]} scale={[100, 2, 1]}/>
        <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[50, 2, 0]} scale={[100, 2, 1]}/>
        {/* Key */}
        {status!==Status.CUSTOM &&
        <Lightformer form="ring" color="red" intensity={10} scale={2} position={[10, 5, 10]}
                     onUpdate={(self) => self.lookAt(0, 0, 0)}/>
        }
      </Environment>

      <Effects/>
    </group>
  );
};


export default EnvironmentController;