import React, { useEffect } from "react";
import { Lamborghini } from "./Lamborghini";
import useRefs from "react-use-refs";
import { ContactShadows, Html } from "@react-three/drei";
import useStatusStore, { Status } from "../store/useStatusStore";
import { animated, useSpring } from "@react-spring/three";

const ObjectController = () => {
  const [lambor, group] = useRefs();
  const { status } = useStatusStore();
  const springs = useSpring({
    color: status === Status.CUSTOM ? "#666666" : "#ff0000",
  });
  //
  useEffect(() => {
    document.body.style.cursor = status === Status.CUSTOM ? "grab" : "default";
  }, [status]);

  return (
    <group ref={group}>
      <Lamborghini ref={lambor} scale={0.012} />
      <mesh
        scale={4}
        position={[3, -1.161, -1.5]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}
      >
        <ringGeometry args={[0.9, 1, 4, 1]} />
        <animated.meshStandardMaterial color={springs.color} roughness={0.75} />
      </mesh>
      <mesh
        scale={4}
        position={[-3, -1.161, -1]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}
      >
        <ringGeometry args={[0.9, 1, 3, 1]} />
        <animated.meshStandardMaterial color={springs.color} roughness={0.75} />
      </mesh>
      <ContactShadows
        resolution={1024}
        frames={1}
        position={[0, -1, 0]}
        scale={12}
        blur={0.5}
        opacity={1}
        far={20}
      />
    </group>
  );
};

export default ObjectController;
