import React from 'react';
import useStatusStore, {Coloring, Status} from "../store/useStatusStore";
import {IconButton} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const CustomController = () => {
  const {status, setStatus, coloring, setColoring, light, setLight} = useStatusStore()

  return (
    <div
      className={"absolute top-0 left-0 w-screen h-screen"}
      style={{pointerEvents: "none"}}
      onWheel={()=>{return false}}
    >
      <div className={"absolute inset-x-0 bottom-0 h-32"}>
        <div className={"w-14 h-16 ml-auto mr-auto content-center"}>
          <IconButton style={{pointerEvents: "auto"}} onClick={() => {
            setStatus(Status.NONE)
            setColoring(Coloring.WHITE)
            setLight(true)
          }} size="large">
            <HighlightOffIcon  fontSize="inherit" />
          </IconButton>
        </div>
      </div>

    </div>
  );
};

export default CustomController;