/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 tree.glb --transform --simplify
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/tree/tree-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <mesh castShadow geometry={nodes.Mesh_0_1.geometry} material={materials.Material_0} />
      <mesh castShadow geometry={nodes.Mesh_0_2.geometry} material={materials.Material_1} />
      <mesh castShadow geometry={nodes.Mesh_0_3.geometry} material={materials.Material_2} />
      <mesh castShadow geometry={nodes.Mesh_0_4.geometry} material={materials.Material_3} />
      <mesh castShadow geometry={nodes.Mesh_0_5.geometry} material={materials.Material_4} />
      <mesh castShadow geometry={nodes.Mesh_0_6.geometry} material={materials.Material_5} />
      <mesh castShadow geometry={nodes.Mesh_0_7.geometry} material={materials.Material_6} />
      <mesh castShadow geometry={nodes.Mesh_0_8.geometry} material={materials.Material_7} />
      <mesh castShadow geometry={nodes.Mesh_0_9.geometry} material={materials.Material_8} />
      <mesh castShadow geometry={nodes.Mesh_0_10.geometry} material={materials.Material_9} />
      <mesh castShadow geometry={nodes.Mesh_0_11.geometry} material={materials.Material_10} />
      <mesh castShadow geometry={nodes.Mesh_0_12.geometry} material={materials.Material_11} />
      <mesh castShadow geometry={nodes.Mesh_0_13.geometry} material={materials.Material_12} />
      <mesh castShadow geometry={nodes.Mesh_0_14.geometry} material={materials.Material_13} />
      <mesh castShadow geometry={nodes.Mesh_0_15.geometry} material={materials.Material_14} />
      <mesh castShadow geometry={nodes.Mesh_0_16.geometry} material={materials.Material_15} />
      <mesh castShadow geometry={nodes.Mesh_0_17.geometry} material={materials.Material_16} />
      <mesh castShadow geometry={nodes.Mesh_0_18.geometry} material={materials.Material_17} />
      <mesh castShadow geometry={nodes.Mesh_0_19.geometry} material={materials.Material_18} />
      <mesh castShadow geometry={nodes.Mesh_0_20.geometry} material={materials.Material_19} />
      <mesh castShadow geometry={nodes.Mesh_0_21.geometry} material={materials.Material_20} />
      <mesh castShadow geometry={nodes.Mesh_0_22.geometry} material={materials.Material_21} />
      <mesh castShadow geometry={nodes.Mesh_0_23.geometry} material={materials.Material_22} />
      <mesh castShadow geometry={nodes.Mesh_0_24.geometry} material={materials.Material_23} />
      <mesh castShadow geometry={nodes.Mesh_0_25.geometry} material={materials.Material_24} />
      <mesh castShadow geometry={nodes.Mesh_0_26.geometry} material={materials.Material_25} />
      <mesh castShadow geometry={nodes.Mesh_0_27.geometry} material={materials.Material_26} />
      <mesh castShadow geometry={nodes.Mesh_0_28.geometry} material={materials.Material_27} />
      <mesh castShadow geometry={nodes.Mesh_0_29.geometry} material={materials.Material_28} />
      <mesh castShadow geometry={nodes.Mesh_0_30.geometry} material={materials.Material_29} />
      <mesh castShadow geometry={nodes.Mesh_0_31.geometry} material={materials.Material_30} />
      <mesh castShadow geometry={nodes.Mesh_0_32.geometry} material={materials.Material_31} />
      <mesh castShadow geometry={nodes.Mesh_0_33.geometry} material={materials.Material_32} />
      <mesh castShadow geometry={nodes.Mesh_0_34.geometry} material={materials.Material_33} />
      <mesh castShadow geometry={nodes.Mesh_0_35.geometry} material={materials.Material_34} />
      <mesh castShadow geometry={nodes.Mesh_0_36.geometry} material={materials.Material_35} />
      <mesh castShadow geometry={nodes.Mesh_0_37.geometry} material={materials.Material_36} />
      <mesh castShadow geometry={nodes.Mesh_0_38.geometry} material={materials.Material_37} />
      <mesh castShadow geometry={nodes.Mesh_0_39.geometry} material={materials.Material_38} />
      <mesh castShadow geometry={nodes.Mesh_0_40.geometry} material={materials.Material_39} />
    </group>
  )
}

useGLTF.preload('/tree/tree-transformed.glb')
