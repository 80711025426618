import React, {useEffect, useState} from "react";
import {Scroll} from "@react-three/drei";
import useStatusStore, {Status} from "../store/useStatusStore";
import {Box, Button, Container, Grid, Stack, Typography,} from "@mui/material";
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import VrpanoIcon from '@mui/icons-material/Vrpano';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import {createTheme, responsiveFontSizes, styled, ThemeProvider,} from '@mui/material/styles';
import useMobileDetect from 'use-mobile-detect-hook';
import {ARButton, VRButton} from '@react-three/xr'

const ContentsController = () => {
  const {status, setStatus} = useStatusStore();
  const [enableAR, setEnableAR] = useState(false);
  const [enableVR, setEnableVR] = useState(false);
  const detectMobile = useMobileDetect();

  useEffect(() => {
    if ('xr' in navigator) {
      navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
        setEnableAR(supported)
      }).catch();
      navigator.xr.isSessionSupported('immersive-vr').then((supported) => {
        setEnableVR(supported)
      }).catch();
    } else {

    }
  }, [])


  const Item = styled(Box)(({theme}) => ({
    height: window.innerHeight,
  }));
  //
  if (status === Status.CUSTOM) return <Scroll html/>;
  //


  const startAR = () => {
    if (detectMobile.isIos()) {
      window.open("/aerotower/aerotower.usdz", "_blank")
    } else {
      alert("AR 보기는 모바일만 지원합니다.")
    }
  }

  const startVR = () => {
    alert("VR 보기는 VR 기기만 지원합니다.")
  }

  let theme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
      info: {
        main: '#fafafa',
      },
    },
  });
  theme = responsiveFontSizes(theme);

  const buttonStyles = {
    padding: '12px 24px',
    border: '1px solid white',
    borderRadius: '4px',
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'white',
    font: 'normal 0.8125rem sans-serif',
    outline: 'none',
    zIndex: 99999,
    cursor: 'pointer'
  }

  return (
    <Scroll html className="absolute">
      <ThemeProvider theme={theme}>
        <Stack
          direction="column"
          alignItems="stretch"
          className={"w-screen"}
        >
          {/* page 1 */}
          <Item>
            <Container className={"h-full"} maxWidth="lg">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                  <Grid item xs={12}>
                    <img src={"/aerotower/page1.png"}/>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Item>


          {/* page 2 */}
          <Item>
            <Container className={"h-full"} maxWidth="lg">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                  <Grid item md={7} xs={1}/>
                  <Grid item md={5} xs={10}>
                    <Stack spacing={3}>
                      <Typography variant={"h3"} className={"break-keep text-center"}>이제 청정한 공기를
                        계절-상황-공간에 맞춰
                        원하는 온도로</Typography>
                      <Typography variant={"body1"} className={"break-keep text-center"}>이다양한 순간,
                        각자의 취향에 맞춰
                        깨끗한 공기를 원하는 온도로
                        마음 놓고 누릴 수 있도록</Typography>
                      <Typography variant={"body1"} className={"break-keep text-center"}>공기청정팬 LG
                        퓨리케어 에어로타워가
                        더 편안한 사계절 청정 라이프를 선사합니다.</Typography>
                      <Typography variant={"caption"} className={"break-keep text-center"}>*
                        공기청정팬이란? 사용자의 라이프스타일 또는 취향에
                        맞춰
                        청정된 공기의 온도를 따뜻하거나 시원하게 바꿔가며 사용할 수 있는 제품입니다.</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}/>
                </Grid>
              </Box>
            </Container>
          </Item>

          {/* page 3 */}
          <Item>
            <Container className={"h-full"} maxWidth="lg">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                  <Grid item md={1} xs={1}/>
                  <Grid item md={5} xs={10}>
                    <Stack spacing={3}>
                      <Typography variant={"h3"} className={"break-keep text-center"}>사계절 청정으로
                        깨끗함이 다른 공기</Typography>
                      <Typography variant={"body1"} className={"break-keep text-center"}>돌출된 에어가드가
                        앞뒤로 흐르는 공기의 방향을 바꿔
                        공기를 위로 넓게 보냅니다.
                        덕분에 공간 전체가 빠르게 청정되죠.</Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={1}/>

                  <Grid item md={1} xs={12}/>
                  <Grid item md={5} xs={12}><img src={"/aerotower/page3_1.png"}
                                                 className={"p-2"}/></Grid>

                  <Grid item md={5} xs={12}><img src={"/aerotower/page3_2.png"}
                                                 className={"p-2"}/></Grid>
                  <Grid item md={1} xs={12}/>

                </Grid>
              </Box>
            </Container>
          </Item>


          {/* page 4 */}
          <Item>
            <Container className={"h-full"} maxWidth="lg">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                  <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                    <Grid item md={6} xs={1}/>
                    <Grid item md={5} xs={10}>
                      <Stack spacing={3}>
                        <Typography variant={"h3"} className={"break-keep text-center"}>당신을
                          위해<br/>
                          계속 좋아지는 가전</Typography>
                        <Typography variant={"body1"} className={"break-keep text-center"}>LG
                          퓨리케어 에어로타워가 UP 가전으로 더 새로워집니다.
                          휴대폰의 앱이 스스로 업데이트하듯
                          LG 퓨리케어 에어로타워는 사용하면서도
                          새로운 기능을 더하고 내게 맞춰 변화하는
                          업그레이드를 제공합니다.</Typography>
                        <Grid container>
                          <Grid item xs={12} textAlign={"center"}>
                            <Button
                              variant="contained"
                              color={"info"}
                              size={"large"}
                              startIcon={<ThreeSixtyIcon/>}
                              onClick={() => setStatus(Status.CUSTOM)}
                            >더 알아보기</Button>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid item md={1} xs={1}/>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Item>


          {/* page 5 */}
          <Item>
            <Container className={"h-full"} maxWidth="lg">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                  <Grid item md={2} xs={1}/>
                  <Grid item md={8} xs={10}>
                    <Stack spacing={3}>
                      <Typography variant={"h5"} className={"break-keep text-center"}>
                        공간인테리어 가전 - 오브제컬렉션</Typography>
                      <Typography variant={"h3"} className={"break-keep text-center"}>
                        일상에 조화롭게 녹아드는 디자인
                      </Typography>
                      <Typography variant={"body1"} className={"break-keep text-center"}>
                        예술적인 소품처럼 공간을 빛내는 디자인,<br/>
                        AR로 집 안 분위기와 어울리는지 바로 확인해 보세요.
                      </Typography>

                      <Box minHeight={"30vh"}/>


                      <Grid container>
                        <Grid item md={3}/>
                        <Grid item md={3} xs={12} textAlign={"center"} className={"pb-2"}>

                          { !enableAR &&
                            <Button
                              variant="contained"
                              color={"info"}
                              size="large"
                              onClick={startAR}
                              startIcon={<ViewInArIcon/>}
                            >AR 보기</Button>
                          }
                          { enableAR &&
                            <ARButton
                              style={buttonStyles}
                            >AR 보기</ARButton>
                          }
                        </Grid>
                        <Grid item md={3} xs={12} textAlign={"center"} className={"pb-2"}>

                          { !enableVR &&
                            <Button
                              variant="contained"
                              color={"info"}
                              size="large"
                              onClick={startVR}
                              startIcon={<VrpanoIcon/>}
                              hidden={enableVR}
                            >VR 보기</Button>
                          }
                          { enableVR &&
                            <VRButton
                              style={buttonStyles}
                              hidden={!enableVR}
                            >VR 보기</VRButton>
                          }
                        </Grid>
                        <Grid item md={3}/>
                      </Grid>


                      <Box minHeight={"10vh"}/>
                      <Typography variant={"caption"} className={"break-keep text-center"}>
                        * AR은 모바일에서 사용 가능합니다.<br/>
                        * VR은 VR장비에서 사용 가능합니다.
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={2} xs={1}/>
                </Grid>
              </Box>
            </Container>
          </Item>

          {/* page 6 */}
          <Item/>

          {/* page 7 */}
          <Item className="bg-white">
            <Container maxWidth="lg">
              <Box minHeight="10vh"/>
              <img src={"/aerotower/page7.png"}/>
            </Container>
          </Item>
        </Stack>

      </ThemeProvider>
    </Scroll>
  );
};

export default ContentsController;
