import create from 'zustand'

export const Status = {
  NONE: "none",
  CUSTOM: "custom",
  AR: "ar",
  VR: "vr",
}

export const Coloring = {
  BLACK: "black",
  WHITE: "white",
  YELLOW: "yellow",
  RED: "red",
}

const useStatusStore = create(set => ({
  status: Status.NONE,
  setStatus: (s) => set({status: s}),
  coloring: Coloring.BLACK,
  setColoring: (s) => set({coloring: s}),
  light: true,
  setLight: (s) => set({light: s}),
}))

export default useStatusStore