import "../App.css";
import React, { Suspense, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { ScrollControls } from "@react-three/drei";
import { Effects } from "./Effects";
import { Leva } from "leva";
import EnvironmentController from "./EnvironmentController";
import CameraController from "./CameraController";
import ObjectController from "./ObjectController";
import ContentsController from "./ContentsController";
import useStatusStore, { Status } from "../store/useStatusStore";
import { gsap } from "gsap";
import CustomController from "./CustomController";

function Huracan() {
  const { status, setStatus } = useStatusStore();
  const [isScroll, setIsScroll] = useState(true);
  const [page, setPage] = useState(5);

  useEffect(() => {
    console.log("%c App.js status: " + status, "color: cyan");
    const color = status === Status.CUSTOM ? "#cccccc" : "#15151a";
    gsap.to(".gl-canvas", { duration: 2, backgroundColor: color });
  }, [status]);

  return (
  <>
    <Canvas
      gl={{ logarithmicDepthBuffer: true, antialias: false }}
      dpr={[1, 1.5]}
      camera={{ position: [0, 0, 15], fov: 25 }}
      className="gl-canvas"
    >
      {/* 환경 컨트롤러 */}
      <Suspense fallback={null}>
        <EnvironmentController />
      </Suspense>
      {/* 3D 오브젝트 컨트롤러 */}
      <Suspense fallback={null}>
        <ObjectController />
      </Suspense>

      <ScrollControls pages={7}>
        {/* 카메라 컨트롤러 */}
        <Suspense fallback={null}>
          <CameraController />
        </Suspense>
        {/* 웹 콘텐츠 컨트롤러 */}
        <Suspense fallback={null}>
          <ContentsController />
        </Suspense>
      </ScrollControls>

      <Leva hidden />
    </Canvas>

    {status === Status.CUSTOM && <CustomController />}
  </>
);
}

export default Huracan;
