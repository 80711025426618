import React from "react";
import { Scroll } from "@react-three/drei";
import useStatusStore, { Status } from "../store/useStatusStore";
import { Button } from "@mui/material";
import {
  Instagram,
  Facebook,
  Twitter,
  YouTube,
  WhatsApp,
  LinkedIn,
} from "@mui/icons-material";

const ContentsController = () => {
  const { status, setStatus } = useStatusStore();

  //
  if (status === Status.CUSTOM) return <Scroll html />;
  //
  return (
    <Scroll html className="absolute">
      {/* page 1 */}
      <div
        className="w-screen pt-[20vh] pl-[10vw] text-white"
        style={{ height: window.innerHeight }}
      >
        <h1 className="text-3xl font-light">MODELS</h1>
        <h1 className="text-[110px] font-bold relative -left-1.5 -top-8">
          HURACÁN
        </h1>
        <div className="bg-white py-3 opacity-30 mb-3 w-[460px]">
          <img src="/huracan/huracan_logo.png" height="25" width="460" />
        </div>
        <h1 className="leading-5 w-[35vw]">
          Discover all the new models of this exclusive car family, created to
          shape to your deepest emotions. Combining the highest expression of
        </h1>
      </div>
      {/* page 2 */}
      <div
        className="relative w-screen pt-[20vh] pl-[55vw] space-y-4 text-white"
        style={{ height: window.innerHeight }}
      >
        <div>
          <div className="absolute -z-10 left-0 h-14 w-screen opacity-60 bg-red-600" />
          <h1 className="text-5xl font-bold">DESIGN</h1>
        </div>
        <h1 className="leading-5 w-[35vw]">
          spiky hexagonal forms of the carbon atom, while the seamless roof
          profile is an unmistakable mark of the Lamborghini DNA. The Huracán
        </h1>
      </div>
      {/* page 3 */}
      <div
        className="relative w-screen pt-[20vh] pl-[10vw] space-y-4 text-white"
        style={{ height: window.innerHeight }}
      >
        <h1 className="text-5xl font-bold">EXTERIOR</h1>
        <h1 className="leading-5 w-[35vw]">
          A lightweight approach and functional design combine to express pure
          performance. While its contours may be reminiscent of the Huracán
        </h1>
        <Button
          variant="contained"
          color="error"
          style={{ marginTop: 20 }}
          onClick={() => setStatus(Status.CUSTOM)}
        >
          Open it right now
        </Button>
      </div>
      {/* page 4 */}
      <div
        className="relative w-screen pt-[20vh] pl-[55vw] space-y-4 text-white"
        style={{ height: window.innerHeight }}
      >
        <div>
          <div className="absolute -z-10 left-0 h-[150px] w-screen opacity-60 bg-blue-600" />
          <h1 className="text-5xl font-bold whitespace-pre-line">
            {"LIVE AN\nIMMERSIVE 3D\nEXPERIENCE"}
          </h1>
        </div>

        <h1 className="leading-5 w-[35vw]">
          Explore the new Huracán STO through videos, car renderings and much
          more to enjoy every single detail of the latest Lamborghini V10
          creation.
        </h1>
      </div>
      {/* page 5 */}
      <div
        className="relative w-screen pt-[20vh] pl-[10vw] space-y-4 text-white"
        style={{ height: window.innerHeight }}
      >
        <h1 className="text-5xl font-bold">AUGMENTED REALITY</h1>
        <h1 className="leading-5 w-[35vw]">
          Frame the QR code with your smartphone and turn on the Lamborghini
          Augmented Reality experience.
        </h1>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
          onClick={() => window.open("/huracan/lambo_convert.usdz", "_blank")}
        >
          Open it right now
        </Button>
      </div>
      {/* page 6 */}
      <div
        className="relative w-screen pt-[5vh] space-y-24 bg-white"
        style={{ height: window.innerHeight }}
      >
        <h1 className="text-5xl font-bold text-center">
          TECHNICAL SPECIFICATIONS
        </h1>
        <div className="w-screen flex justify-center items-center">
          <ul className="w-[70vw]">
            <li className="py-4 flex justify-between align-items-center border-b">
              <span className="text-xl">Displacement</span>
              <span className="text-xl">5,204 cm³ (317.57 cu in)</span>
            </li>
            <li className="py-4 flex justify-between align-items-center border-b">
              <span className="text-xl">Max. power</span>
              <span className="text-xl">470/640 at 8,000 rpm</span>
            </li>
            <li className="py-4 flex justify-between align-items-center border-b">
              <span className="text-xl">Top speed</span>
              <span className="text-xl">310 km/h</span>
            </li>
            <li className="py-4 flex justify-between align-items-center border-b">
              <span className="text-xl">
                Acceleration 0-100 km/h (MPH 0-62)
              </span>
              <span className="text-xl">3.0 s</span>
            </li>
            <li className="py-4 flex justify-between align-items-center border-b">
              <span className="text-xl">BRAKING 100-0 KM/H (62-0 MPH)</span>
              <span className="text-xl">30 m</span>
            </li>
            <li className="py-4 flex justify-between align-items-center border-b">
              <span className="text-xl">Combined consumption *</span>
              <span className="text-xl">6.3 l/100km</span>
            </li>
            <li className="py-4 flex justify-between align-items-center border-b">
              <span className="text-xl">Combined CO2 Emissions *</span>
              <span className="text-xl">279 g/km</span>
            </li>
            <li className="py-4 flex justify-between align-items-center border-b">
              <span className="text-xl">CHASSIS</span>
              <span className="text-xl">
                Hybrid in aluminum and carbon fiber
              </span>
            </li>
            <li className="py-4 flex justify-between align-items-center border-b">
              <span className="text-xl">BODY</span>
              <span className="text-xl">Aluminum and synthetic material</span>
            </li>
          </ul>
        </div>
      </div>
      {/* page 7 */}
      <div
        className="relative w-screen text-white"
        style={{ height: window.innerHeight }}
      >
        {/* contact your dealer */}
        <div className="h-80">
          <div className="absolute w-screen h-80 pt-16 pl-[55vw] space-y-5">
            <h1 className="text-5xl font-bold whitespace-pre-line">
              {"CONTACT\nYOUR DEALER"}
            </h1>
            <Button variant="contained" color="success">
              Contact right now
            </Button>
          </div>
          <img src="/huracan/hura_sto.png" className="w-screen h-80 object-cover" />
        </div>
        {/* contact your dealer */}
        <div className="h-52">
          <div className="absolute w-screen h-52 pt-5 pl-[10vw] space-y-5">
            <h1 className="text-5xl font-bold whitespace-pre-line">
              {"CONNECT\nWITH YOUR HURACÁN"}
            </h1>
            <Button variant="contained" color="success">
              Connected Services of HURACÁN
            </Button>
          </div>
          <img src="/huracan/hura_connect.png" className="w-screen h-52 object-cover" />
        </div>
        {/* bottom */}
        <div className="w-screen h-full bg-[#181818] py-10">
          {/* Instagram, Facebook, Twitter, YouTube, WhatsApp, LinkedIn */}
          <div className="px-[5vw] mb-10 space-x-3">
            <Instagram fontSize="small" className="text-white" />
            <Facebook fontSize="small" className="text-white" />
            <Twitter fontSize="small" className="text-white" />
            <YouTube fontSize="small" className="text-white" />
            <WhatsApp fontSize="small" className="text-white" />
            <LinkedIn fontSize="small" className="text-white" />
          </div>
          <div className="w-[90vw] ml-[5vw] text-white text-sm font-light whitespace-pre-line">
            {
              "The consumption and emissions values in the website refer to your geographical IP. This value might be unrealistic if you navigate using VPN or if the position of your Internet provider is imprecise. If you believe you are incorrectly geolocalized, contact your dealer to get valid consumption and emissions information in your area."
            }
            {"\n\n"}
            {
              "Not all contents are available in all markets. Please check with your local Lamborghini Dealer for details and availability."
            }
          </div>
          <div className="w-[90vw] mt-8 ml-[5vw] text-white font-light whitespace-pre-line">
            {
              "Copyright © 2022 Automobili Lamborghini S.p.A. a sole shareholder company part of Audi Group."
            }
            {"\n"}
            {"All rights reserved. VAT no. IT 00591801204"}
          </div>
        </div>
      </div>
    </Scroll>
  );
};

export default ContentsController;
