import React, {Suspense, useEffect, useRef, useState} from 'react'
import {Canvas, useFrame} from '@react-three/fiber'
import {
  AccumulativeShadows,
  Environment,
  Grid,
  Lightformer, PerformanceMonitor,
  RandomizedLight,
  ScrollControls,
  Stage
} from '@react-three/drei'
import {Bloom, EffectComposer} from '@react-three/postprocessing'
import {Model} from "./Model";
import CameraController from "./CameraController";
import ContentsController from "./ContentsController";
import useStatusStore, {Coloring, Status} from "../store/useStatusStore";
import CustomController from "./CustomController";
import {Controllers, Hands, XR} from '@react-three/xr'
import { easing } from 'maath'

function Env({ perfSucks }) {
  const ref = useRef()
  useFrame((state, delta) => {
    // Animate the environment as well as the camera
    // if (!perfSucks) {
    //   easing.damp3(ref.current.rotation, [Math.PI / 2, 0, state.clock.elapsedTime / 5 + state.pointer.x], 0.2, delta)
    //   easing.damp3(state.camera.position, [Math.sin(state.pointer.x / 4) * 9, 1.25 + state.pointer.y, Math.cos(state.pointer.x / 4) * 9], 0.5, delta)
    //   state.camera.lookAt(0, 0, 0)
    // }
  })
  // Runtime environments can be too slow on some systems, better safe than sorry with PerfMon
  return (
    <Environment frames={perfSucks ? 1 : Infinity} files="/global/potsdamer_platz_1k.hdr" resolution={256} background blur={0.8}>
      <Lightformer intensity={4} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[10, 10, 1]} />
      <Lightformer intensity={4} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[10, 10, 1]} />
      <group rotation={[Math.PI / 2, 1, 0]}>
        {[2, -2, 2, -4, 2, -5, 2, -9].map((x, i) => (
          <Lightformer key={i} intensity={1} rotation={[Math.PI / 4, 0, 0]} position={[x, 4, i * 4]} scale={[4, 1, 1]} />
        ))}
        <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
        <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={[50, 2, 1]} />
        <Lightformer intensity={0.5} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[50, 2, 1]} />
      </group>
      <group ref={ref}>
        <Lightformer intensity={5} form="ring" color="green" rotation-y={Math.PI / 2} position={[-5, 2, -1]} scale={[10, 10, 1]} />
      </group>
    </Environment>
  )
}

const Tree = () => {
  const {status, setStatus, setColoring} = useStatusStore();
  const [perfSucks, degrade] = useState(false)

  useEffect(() => {
    setColoring(Coloring.WHITE)
  }, []);

  useEffect(() => {
    console.log("%c status: " + status, "color: red")
    if(status===Status.NONE){
      const list = document.getElementsByTagName("div");
      for (const l of list) {
        // console.log(l.style)
        l.style.removeProperty('touch-action');
      }
    }
  }, [status]);

  const handleSessionStart = (e) => {
    console.log(e)
    if(e.target.environmentBlendMode === "alpha-blend"){
      setStatus(Status.AR);
    } else {
      setStatus(Status.VR);
    }
  }

  const handleSessionEnd = (e) => {
    setStatus(Status.NONE);
  }

  return (<>
    <Canvas
      gl={{logarithmicDepthBuffer: true}}
      shadows
      dpr={[1, perfSucks ? 1.5 : 2]}
      // eventSource={document.getElementById('root')}
      // eventPrefix="client"
      camera={{position: [-5.9, 3.4, 3.8], fov: 25}}
    >
      <XR onSessionStart={handleSessionStart} onSessionEnd={handleSessionEnd} referenceSpace="local">
        {status === Status.AR &&
          <>
            <Model rotation={[0, 0, 0]} scale={0.3} position={[0, 0, -1]}/>
            <Environment files="/global/venice_sunset_1k.hdr" blur={0.8}/>
            <Grid renderOrder={-1} position={[0, -0.9, 0]} infiniteGrid cellSize={0.6} cellThickness={0.6}
                  sectionSize={3.3} sectionThickness={1.5} sectionColor={[0.5, 0.5, 10]} fadeDistance={30}/>
          </>
        }

        {status === Status.VR &&
          <>
            <Controllers/>
            <Hands/>
            <fog attach="fog" args={['black', 15, 21.5]}/>
            <Model rotation={[0, 0, 0]} scale={0.3} position={[0, 0, -1]}/>
            <Environment background files="/global/venice_sunset_1k.hdr" blur={0.8}/>
            <Grid renderOrder={-1} position={[0, -0.9, 0]} infiniteGrid cellSize={0.6} cellThickness={0.6}
                  sectionSize={3.3} sectionThickness={1.5} sectionColor={[0.5, 0.5, 10]} fadeDistance={30}/>

          </>
        }

        {
          (status === Status.NONE || status === Status.CUSTOM) &&
          <>
            <PerformanceMonitor onDecline={() => degrade(true)} />
            {/*<color attach="background" args={['#f0f0f0']} />*/}
            <group position={[0, -0.5, 0]} rotation={[0, -0.75, 0]}>
              <Model rotation={[0, 0, 0]} position={[0, 1, 0]}  />
              <AccumulativeShadows frames={100} alphaTest={0.85} opacity={0.8} color="green" scale={20} position={[0, -0.01, 0]}>
                <RandomizedLight amount={8} radius={6} ambient={0.5} intensity={1} position={[-1.5, 2.5, -2.5]} bias={0.001} />
              </AccumulativeShadows>
            </group>
            <Env perfSucks={perfSucks} />

            <ScrollControls pages={7}>
              {/* 카메라 컨트롤러 */}
              <Suspense fallback={null}>
                <CameraController/>
              </Suspense>
              {/* 웹 콘텐츠 컨트롤러 */}
              <Suspense fallback={null}>
                <ContentsController/>
              </Suspense>
            </ScrollControls>
          </>
        }

      </XR>
    </Canvas>

    {status === Status.CUSTOM && <CustomController/>}
  </>);
};

export default Tree;