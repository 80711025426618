import React from 'react';
import useStatusStore, {Coloring, Status} from "../store/useStatusStore";
import {IconButton} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import FlashOnIcon from '@mui/icons-material/FlashOn';

const CustomController = () => {
  const {status, setStatus, coloring, setColoring, light, setLight} = useStatusStore()

  return (
    <div className={"absolute top-0 left-0 w-screen h-screen"} style={{pointerEvents: "none"}} onWheel={()=>{return false}}   >

      <div className={"absolute inset-y-0 left-0 w-64 "}>
        <div className="h-screen grid content-center">
          <div className={"grid justify-items-center gap-4"}>
            <IconButton style={{pointerEvents: "auto"}} className={(coloring===Coloring.BLACK)?"!border-solid !border-2 !border-neutral-500":""} onClick={() => setColoring(Coloring.BLACK)} >
              <Brightness1Icon sx={{color: "black"}} />
            </IconButton>
            <IconButton style={{pointerEvents: "auto"}} className={(coloring===Coloring.WHITE)?"!border-solid !border-2 !border-neutral-500":""} onClick={() => setColoring(Coloring.WHITE)}>
              <Brightness1Icon sx={{color: "white"}} />
            </IconButton>
            <IconButton style={{pointerEvents: "auto"}} className={(coloring===Coloring.YELLOW)?"!border-solid !border-2 !border-neutral-500":""} onClick={() => setColoring(Coloring.YELLOW)}>
              <Brightness1Icon sx={{color: "yellow"}} />
            </IconButton>
            <IconButton style={{pointerEvents: "auto"}} className={(coloring===Coloring.RED)?"!border-solid !border-2 !border-neutral-500":""} onClick={() => setColoring(Coloring.RED)}>
              <Brightness1Icon sx={{color: "red"}} />
            </IconButton>
            <IconButton style={{pointerEvents: "auto"}}  onClick={() => setLight(!light)}>
              {light&&<FlashOffIcon sx={{color: "white"}}/>}
              {!light&&<FlashOnIcon sx={{color: "white"}}/>}
            </IconButton>
          </div>
        </div>
      </div>

      <div className={"absolute inset-x-0 bottom-0 h-32"}>
        <div className={"w-14 h-16 ml-auto mr-auto content-center"}>
          <IconButton style={{pointerEvents: "auto"}} onClick={() => {
            setStatus(Status.NONE)
            setColoring(Coloring.BLACK)
            setLight(true)
          }} size="large">
            <HighlightOffIcon  fontSize="inherit" />
          </IconButton>
        </div>
      </div>

    </div>
  );
};

export default CustomController;