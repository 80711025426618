import React, {Suspense, useEffect} from 'react'
import {Canvas} from '@react-three/fiber'
import {Environment, Grid, ScrollControls, Stage} from '@react-three/drei'
import {Bloom, EffectComposer} from '@react-three/postprocessing'
import {Model} from "./Model";
import CameraController from "./CameraController";
import ContentsController from "./ContentsController";
import useStatusStore, {Coloring, Status} from "../store/useStatusStore";
import CustomController from "./CustomController";
import {Controllers, Hands, XR} from '@react-three/xr'

const AeroTower = () => {
  const {status, setStatus, setColoring} = useStatusStore();

  useEffect(() => {
    setColoring(Coloring.WHITE)
  }, []);

  useEffect(() => {
    console.log("%c status: " + status, "color: red")
    if(status===Status.NONE){
      const list = document.getElementsByTagName("div");
      for (const l of list) {
        // console.log(l.style)
        l.style.removeProperty('touch-action');
      }
    }
  }, [status]);

  const handleSessionStart = (e) => {
    console.log(e)
    if(e.target.environmentBlendMode === "alpha-blend"){
      setStatus(Status.AR);
    } else {
      setStatus(Status.VR);
    }
  }

  const handleSessionEnd = (e) => {
    setStatus(Status.NONE);
  }

  return (<>
    <Canvas
      gl={{logarithmicDepthBuffer: true}}
      shadows
      camera={{position: [-15, 0, 10], fov: 25}}
    >
      <XR onSessionStart={handleSessionStart} onSessionEnd={handleSessionEnd} referenceSpace="local">


        {status === Status.AR &&
          <>
            <Model rotation={[0, 0, 0]} scale={1} position={[0, 0, -1]}/>
            <Grid renderOrder={-1} position={[0, -0.9, 0]} infiniteGrid cellSize={0.6} cellThickness={0.6}
                  sectionSize={3.3} sectionThickness={1.5} sectionColor={[0.5, 0.5, 10]} fadeDistance={30}/>
          </>
        }

        {status === Status.VR &&
          <>
            <Controllers/>
            <Hands/>
            <fog attach="fog" args={['black', 15, 21.5]}/>
            <Model rotation={[0, 0, 0]} scale={1} position={[0, 0, -1]}/>
            <Environment background files="/global/venice_sunset_1k.hdr" blur={0.8}/>
            <Grid renderOrder={-1} position={[0, -0.9, 0]} infiniteGrid cellSize={0.6} cellThickness={0.6}
                  sectionSize={3.3} sectionThickness={1.5} sectionColor={[0.5, 0.5, 10]} fadeDistance={30}/>

          </>
        }

        {
          (status === Status.NONE || status === Status.CUSTOM) &&
          <>
            <fog attach="fog" args={['black', 15, 21.5]}/>
            <Stage intensity={0.5} environment={{files: "/global/potsdamer_platz_1k.hdr"}}
                   shadows={{type: 'accumulative', bias: -0.001}} adjustCamera={false}>
              <Model rotation={[0, 0, 0]} scale={3.3}/>
            </Stage>
            <EffectComposer disableNormalPass>
              <Bloom luminanceThreshold={1} mipmapBlur/>
            </EffectComposer>
            <Environment background files="/global/venice_sunset_1k.hdr" blur={0.8}/>
            <ScrollControls pages={7}>
              {/* 카메라 컨트롤러 */}
              <Suspense fallback={null}>
                <CameraController/>
              </Suspense>
              {/* 웹 콘텐츠 컨트롤러 */}
              <Suspense fallback={null}>
                <ContentsController/>
              </Suspense>
            </ScrollControls>
          </>
        }

      </XR>
    </Canvas>

    {status === Status.CUSTOM && <CustomController/>}
  </>);
};

export default AeroTower;