import React, {useEffect, useState} from "react";
import {Scroll} from "@react-three/drei";
import useStatusStore, {Status} from "../store/useStatusStore";
import {Box, Button, Container, Grid, Stack, Typography,} from "@mui/material";
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import VrpanoIcon from '@mui/icons-material/Vrpano';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import {createTheme, responsiveFontSizes, styled, ThemeProvider,} from '@mui/material/styles';
import useMobileDetect from 'use-mobile-detect-hook';
import {ARButton, VRButton} from '@react-three/xr'

const ContentsController = () => {
  const {status, setStatus} = useStatusStore();
  const [enableAR, setEnableAR] = useState(false);
  const [enableVR, setEnableVR] = useState(false);
  const detectMobile = useMobileDetect();

  useEffect(() => {
    if ('xr' in navigator) {
      navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
        setEnableAR(supported)
      }).catch();
      navigator.xr.isSessionSupported('immersive-vr').then((supported) => {
        setEnableVR(supported)
      }).catch();
    } else {

    }
  }, [])


  const Item = styled(Box)(({theme}) => ({
    height: window.innerHeight,
  }));

  const startAR = () => {
    if (detectMobile.isIos()) {
      window.open("/tree/tree.usdz", "_blank")
    } else {
      alert("AR 보기는 모바일만 지원합니다.")
    }
  }

  const startVR = () => {
    alert("VR 보기는 VR 기기만 지원합니다.")
  }

  let theme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
      info: {
        main: '#fafafa',
      },
    },
  });
  theme = responsiveFontSizes(theme);

  const buttonStyles = {
    padding: '12px 24px',
    border: '1px solid white',
    borderRadius: '4px',
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'white',
    font: 'normal 0.8125rem sans-serif',
    outline: 'none',
    zIndex: 99999,
    cursor: 'pointer'
  }

  return (
    <Scroll html className="absolute" id={"contents"}>
      {(status === Status.NONE) &&
      <ThemeProvider theme={theme}>
        <Stack
          direction="column"
          alignItems="stretch"
          className={"w-screen"}
        >
          {/* page 1 */}
          <Item>
            <Container className={"h-full"} maxWidth="lg">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                  <Grid item md={1} xs={1}/>
                  <Grid item md={5} xs={10}>
                    <Stack spacing={3}>
                      <Typography variant={"h3"} className={"break-keep"}>
                        행운과 재물을 부르는<br/>
                        녹색보석나무 '녹보수'
                      </Typography>
                      <Typography variant={"body1"} className={"break-keep"}>
                        행운,행복,재물의 꽃말을 가지고 있는<br/>
                        녹색보석나무 녹보수 입니다.
                      </Typography>
                      <Typography variant={"body1"} className={"break-keep"}>
                        좋은 의미를 가지고 있어<br/>
                        개업,승진,집들이 선물로 유명하답니다.
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={1}/>

                </Grid>
              </Box>
            </Container>
          </Item>


          {/* page 2 */}
          <Item>
            <Container className={"h-full"} maxWidth="lg">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                  <Grid item md={7} xs={1}/>
                  <Grid item md={5} xs={10}>
                    <Stack spacing={3}>
                      <Typography variant={"h3"} className={"break-keep text-center"}>
                        살아있는 식물이라<br/>
                        '수형이 모두 달라요'
                      </Typography>
                      <Typography variant={"body1"} className={"break-keep text-center"}>
                        식물의 생상은 사진보다 진하거나 옅을 수 있습니다.<br/>
                        농장 및 유통과정에서 식물의 미세한 찍힘이나<br/>
                        잎의 상처는 자연스러운 현상이며,<br/>
                        이러한 사유로 인한 교환, 환불은 불가하니<br/>
                        구매전 유의해주세요.<br/>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}/>
                </Grid>
              </Box>
            </Container>
          </Item>

          {/* page 3 */}
          <Item>
            <Container className={"h-full"} maxWidth="lg">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                  <Grid item md={1} xs={1}/>
                  <Grid item md={5} xs={10}>
                    <Stack spacing={3}>
                      <Typography variant={"h3"} className={"break-keep text-center"}>
                        / Pot option /
                      </Typography>
                      <Typography variant={"body1"} className={"break-keep text-center"}>
                        모니터 화면의 해상도나 설정에 따라 사진과 실물 색상의 차이가 있을 수 있습니다.
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={1}/>

                  <Grid item md={1} xs={12}/>
                  <Grid item md={5} xs={12} textAlign={"center"}>
                    <img src={"/tree/page3_1.png"} className={"p-2 max-h-[30vh] mx-auto"}/>
                  </Grid>

                  <Grid item md={5} xs={12}>
                    <img src={"/tree/page3_2.png"} className={"p-2  max-h-[30vh] mx-auto"}/>
                  </Grid>
                  <Grid item md={1} xs={12}/>

                </Grid>
              </Box>
            </Container>
          </Item>


          {/* page 4 */}
          <Item>
            <Container className={"h-full"} maxWidth="lg">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                  <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                    <Grid item md={6} xs={1}/>
                    <Grid item md={5} xs={10}>
                      <Stack spacing={3}>
                        <Typography variant={"h3"} className={"break-keep text-center"}>
                          / SIZE /
                        </Typography>
                        <Typography variant={"body1"} className={"break-keep text-center"}>
                          화분 윗 지름 : 17 cm <br/>
                          화분 높이 : 15 cm <br/>
                          식물포함 높이 : 35 ~ 45 cm <br/>
                        </Typography>
                        <Typography variant={"caption"} className={"break-keep text-center"}>
                          살아 있는 식물의 특성상 크기에 차이가 있을 수 있습니다.
                        </Typography>
                        <Grid container>
                          <Grid item xs={12} textAlign={"center"}>
                            <Button
                              variant="contained"
                              color={"info"}
                              size={"large"}
                              startIcon={<ThreeSixtyIcon/>}
                              onClick={() => setStatus(Status.CUSTOM)}
                            >더 알아보기</Button>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid item md={1} xs={1}/>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Item>


          {/* page 5 */}
          <Item>
            <Container className={"h-full"} maxWidth="lg">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                  <Grid item md={2} xs={1}/>
                  <Grid item md={8} xs={10}>
                    <Stack spacing={3}>
                      <Typography variant={"h5"} className={"break-keep text-center"}>
                        플랜테리어식물전
                      </Typography>
                      <Typography variant={"h3"} className={"break-keep text-center"}>
                        당신만의 홈가드닝
                      </Typography>
                      <Typography variant={"body1"} className={"break-keep text-center"}>
                        예술적인 소품처럼 공간을 빛내는 식물,<br/>
                        내 공간에서 어울리는지 AR 로 바로 확인해보세요.
                      </Typography>

                      <Box minHeight={"30vh"}/>


                      <Grid container>
                        <Grid item md={3}/>
                        <Grid item md={3} xs={12} textAlign={"center"} className={"pb-2"}>

                          { !enableAR &&
                            <Button
                              variant="contained"
                              color={"info"}
                              size="large"
                              onClick={startAR}
                              startIcon={<ViewInArIcon/>}
                            >AR 보기</Button>
                          }
                          { enableAR &&
                            <ARButton
                              style={buttonStyles}
                            >AR 보기</ARButton>
                          }
                        </Grid>
                        <Grid item md={3} xs={12} textAlign={"center"} className={"pb-2"}>

                          { !enableVR &&
                            <Button
                              variant="contained"
                              color={"info"}
                              size="large"
                              onClick={startVR}
                              startIcon={<VrpanoIcon/>}
                              hidden={enableVR}
                            >VR 보기</Button>
                          }
                          { enableVR &&
                            <VRButton
                              style={buttonStyles}
                              hidden={!enableVR}
                            >VR 보기</VRButton>
                          }
                        </Grid>
                        <Grid item md={3}/>
                      </Grid>


                      <Box minHeight={"10vh"}/>
                      <Typography variant={"caption"} className={"break-keep text-center"}>
                        * AR은 모바일에서 사용 가능합니다.<br/>
                        * VR은 VR장비에서 사용 가능합니다.
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={2} xs={1}/>
                </Grid>
              </Box>
            </Container>
          </Item>

          {/* page 6 */}
          <Item/>

          {/* page 7 */}
          <Item className="bg-white">
            <Container maxWidth="lg">
              <Box minHeight="10vh"/>
              <img src={"/tree/page7.png"}/>
            </Container>
          </Item>
        </Stack>

      </ThemeProvider>
      }
    </Scroll>
  );
};

export default ContentsController;
