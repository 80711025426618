import React, {useEffect} from "react";
import useStatusStore, {Status} from "../store/useStatusStore";
import {OrbitControls, useScroll} from "@react-three/drei";
import {useFrame, useThree} from "@react-three/fiber";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/all";
import {Power2} from "gsap/gsap-core";
import {Vector3} from "three";

gsap.registerPlugin(ScrollTrigger);

const positions = [
  new Vector3(-5.9, 5.4, 7.8),
  new Vector3(0, -0.5, 5),
  new Vector3(0, 1, 5),
  new Vector3(8, -1, 6),
  new Vector3(0, 10, 1),
  new Vector3(-0.06, 2.26, 10.6),
  new Vector3(-0.06, 2.26, 10.6),
];

const looks = [
  new Vector3(0, 0, 0),
  new Vector3(0, -0.5, 0),
  new Vector3(0, 1, 0),
  new Vector3(0, 0, 0),
  new Vector3(0, 0, 0),
  new Vector3(0, 0, 0),
  new Vector3(0, 0, 0),
];

const CameraController = () => {
  const { status } = useStatusStore();
  const scroll = useScroll();
  const { camera } = useThree();
  const lookTarget = looks[0];

  const minmax = (num, min, max) => Math.min(Math.max(num, min), max)
  const printVector = (v) => "[x: " + v.x.toFixed(2) + ", y: " + v.y.toFixed(2) + ", z: " + v.z.toFixed(2) + "]";

  useFrame((state, delta) => {
    if (status === Status.NONE) {

      const page = (scroll.offset / (1 / (scroll.pages-1)));
      const currentPage = parseInt(page);
      const offset = page % 1;
      const currenPosition = positions[currentPage];
      const nextPosition = positions[currentPage+1] ? positions[currentPage+1] : positions[currentPage];
      const currenLook = looks[currentPage];
      const nextLook = looks[currentPage+1] ? looks[currentPage+1] : looks[currentPage];

      try {
        camera.position.lerpVectors(currenPosition, nextPosition, offset);
        lookTarget.lerpVectors(currenLook, nextLook, offset);
        camera.lookAt(lookTarget.x, lookTarget.y, lookTarget.z);
      } catch (e) {
        // console.log("page: " + currentPage + ", offset: " + offset.toFixed(2) + ",scroll: " + scroll.offset.toFixed(2)
        //   + ", current: " + printVector(currenPosition) + ", next:" + printVector(nextPosition)
        //   + ", camera: " + printVector(camera.position)
        // );
      }
    }
  });

  useEffect(() => {
    if (status === Status.CUSTOM) {
      gsap.to(camera.position, {
        duration: 1.5,
        x: 0,
        y: 0,
        z: 15,
        ease: Power2.easeInOut,
        onUpdate: () => {
          camera.lookAt(0, 0, 0);
        },
      });
    }
  }, [status]);

  return (
    <>
      {status === Status.CUSTOM && (
        <OrbitControls
          target={lookTarget.set(0, 0, 0)}
          // enablePan={false}
          // enableZoom={false}
          minPolarAngle={Math.PI / 2.2}
          maxPolarAngle={Math.PI / 2.2}
        />
      )}
    </>
  );
};

export default CameraController;
