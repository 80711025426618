import "./App.css";
import React from "react";
import {Route, Routes} from "react-router-dom";
import About from "./about";
import Huracan from "./huracan";
import AeroTower from "./aerotower";
import Tree from "./tree";

function App() {
  return (
    <Routes>
      <Route path="*" element={<About />} />
      <Route path="huracan" element={<Huracan />} />
      <Route path="aerotower" element={<AeroTower />} />
      <Route path="tree" element={<Tree />} />
    </Routes>
  );
}

export default App;
